$authBackground: rgb(247, 219, 136);
$authHeaderText: rgb(228, 245, 255);

$buttonPrimColor: rgba(49, 45, 28, 0.623);
$buttonSecColor: rgb(4, 0, 253);

$commandColor: white;
$commandBckColor: black;
/*Pre Auth*/
.pre-auth-wrap{
    width: 100%;
    height: 100%;
    font-size: 20px;
    opacity: 0.8;
}
.pre-auth-wrap header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 15%;
    min-height: 90px;
    color: #fff;
    font-size: 1.2em;
    padding: 0 10px;
    text-align: left;
    background-color: black;
}
.pre-auth-wrap header .title{
    font-size: 1em;
}
.pre-auth-wrap header .subtitle{
    font-size: 0.7em;
}
.pre-auth-wrap .pre-auth-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 85%;
    min-height: 500px;
    color: black;
    font-size: 1em;
    padding: 10px;
    text-align: left;
    background-color: inherit;
}
/*Auth*/
.auth{
    width: 100%;
    height: 100vh;
    background-color: $authBackground;
    opacity: 0.7;
    z-index: -1;
}
.auth header{
    width: 100%;
    color: $authHeaderText;
    font-size: 1.2rem;
    padding: 2vh 2vw;
    text-align: left;
    background-color: black;
}
.auth-wrap{
    min-height: 300px;
}
.input-wrap{
    width: 100%;
    height: fit-content;
    padding: 0.5vh 0.2vw;
}
.input-wrap .input-element{
    padding: 2px;
    border-radius: 5px;
}
.input-wrap .input-element:hover{
    outline: 2px solid black;
}
.input-wrap .input-element:disabled:hover{
    outline: none;
}
.input-wrap input[type=password], .input-wrap input[type=email], .input-wrap input[type=text]{
    padding: 5px 10px;
    font-size: inherit;
    border-radius: 5px;
    border: 0.1vh solid rgb(131, 131, 131);
    width: 70%;
}
.input-wrap textarea{
    padding: 0.5vh 0.5vw;
    border-radius: 5px;
}
.input-wrap select{
    padding: 0.5vh 0.5vw;
    border-radius: 5px;
}
.input-wrap input[type=file]{
    font-size: inherit;
    border-radius: 5px;
}
.input-wrap .radio-wrap{
    display: flex;
}
.input-wrap input[type=text]:disabled{
    background-color: lightgray;
}
.title{
    font-size: 1.5rem;
}
.subtitle{
    font-size: 1rem;
    margin: 1vh 0 0 0;
}
.msg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50px;
    height: auto;
    border: none;
    margin: 0.3rem 0;
}
.msg .err{
    color: rgb(255, 146, 146);
}
.msg .success{
    color: rgb(91, 98, 181);
}
.full-w{
    width: 100%;
}
.inside{
    font-size: 0.8rem;
}
.hidden{
    font-size: 0.6rem;
    height: fit-content;
    min-height: fit-content;
}
button{
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 15px;
}
button:hover{
    transition: all 0.3s ease-in-out;
    background-color: #000;
    color: #fff;
}
.command{
    color: $commandColor;
    background: $commandBckColor;
}
.indicator{
    width: fit-content;
    min-width: 100px;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    outline: 1px solid rgb(118, 118, 118);
}
.fa-caret-right{
    margin: 0 0.3rem;
}
.ov-x{
    overflow-x: auto;
}
.content .sub-content{
    margin: 0.2rem 0;
    padding: 0.4rem;
}

.content .sub-content .title{
    margin: 0 0 1rem 0;
}

.content .sub-content section{
    display: flex;
    flex-direction: column;
}
.pointer{
    cursor: pointer;
}
.it{
    font-style: italic;
}
.justify-text{
    text-align: justify;
}
.clickable-icon{
    font-size: 1em;
    margin: 5px;
    cursor: pointer;
}
.clickable-icon:hover{
    font-size: 1.1em;
    transition: all 0.3s ease-in-out;
}
.delete-button:hover{
    transition: all 0.3s ease-in-out;
    background-color: rgb(255, 100, 100);
    color: #fff;
}

