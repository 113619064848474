#keyboard{
    height: 100%;
    width: 100%;
    padding: 5px 0;
}
.row{
    display: flex;
    justify-content: space-between;
    gap: 0.25em;
    width: 100%;
    height: 33%;
}
.row + .row {
    margin-top: 0.25em;
}
.row:last-child {
    justify-content: flex-start;
}
.key{
    font-size: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    user-select: none;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    aspect-ratio: 1/1;
    max-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.key:hover{
    opacity: 0.8;
}

.enter-key{
    flex-grow: 2;
}

.key-correct{
    background: #e40029;
}

.key-incorrect{
    background: rgb(113, 113, 113);
}

.key-misplaced{
    background: #ffbd00;
}
@media only screen and (max-width: 280px) { /* smartphones, iPhone, portrait 480x320 phones */
    .row{
        gap: 2px;
    }
}
@media only screen and (min-height:0px) and (max-height: 750px) { /* smartphones, iPhone, portrait 480x320 phones */ 
    .key{
        width: 22px;
    }
}