$letterContainerSquareSide: 10%;

/*Loader*/
.loader-game{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 100%;
}
/*Game Wrapper (for displaying background primarily)*/
#game-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-position: center;
    min-height: 500px;
}
/*Game*/
#game{
    width: 100%;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
    background-size: 50%;
}
/*Game board*/
.game-board-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    width: 100%;
}
/*Board*/
#board{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
/*Rows*/
.row-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 16%;
}
/*Letter*/
.letter-container {
    border-color: #73adff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 38px;
    aspect-ratio: 1/1;
    outline: 2px solid #fff;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
    background-color: #0074c2;
    position: relative;
    z-index: 0;
}
.letter-container > p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-size: 28px;
}
@media (max-width: 350px){
    .letter-container {
        width: 30px;
    }
}
.misplaced{
    border-radius: 50%;
    width: 90% !important;
    height: 90% !important;
    margin-right: 4%;
    margin-bottom: 2%;
}

/*Game logo*/
.game-logo-container{
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 100;
    height: 15%;
}
.game-logo-container img{
    width: 100%;
    height: auto;
}
/*Message*/
#msg{
    padding: 0.8em 2em;
    border-radius: 1em;
    background-color: #57001E;
    transition: opacity linear 1s;
    width: 100%;
    min-width: 230px;
}
@media (max-width: 350px){
    #msg {
        font-size: 13px;
    }
}
#claim-container{
    font-size: 1.1em;
}
.top-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8%;
    max-height: 70px;
    font-size: 1em;
    width: 100%;
    padding: 5px 0;
}
.bottom-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 100%;
    height: 22%;
    padding: 5px 0;
}
.banner-link{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.text-link{

}
.image-link{
    height: 90%;
    width: auto;
}
.keyboard-container{
    display: flex;
    justify-content: center;
    height: 15%;
    width: 90%;
}
.keyboard-container .key{
    background-color: hsla(0, 0%, 0%, 0.2);
}
