.not-found {
    height: 100vh;
    width: 100%;
    max-width: 270px;
    font-size: 2em;
    color: black;
    background-color: inherit;
}
.not-found .container {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}
.not-found .motuz-title {
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.not-found .motuz-title:hover{
    scale: 1.1;
}
.not-found .motuz-title img{
    width: 100%;
}